
.overview-bottom {
    height: 100%;
    width: 100%;
}

.overview-top {
    display: flex;
    height: auto;
    margin-bottom: 15px;
}

.home_info {
    padding: 15px 20px;
    border: 1px solid #3fa7d6;
    width: 100%;
    min-height: 50%;
    max-height: 100%;
    border-radius: 10px;
    margin-left: none;
    background: #F9F9F9;
    font-size: 25px;
    flex-shrink: 3;
}

.projects-overview {
    padding: 15px 20px;
    border: 1px solid #3fa7d6;
    width: 100%;
    min-height: 100%;
    border-radius: 10px;
    background: #F9F9F9;
    font-size: 25px;
    flex-shrink: 4;
    margin-left: 15px;
}

.recent-notes {
    padding: 15px 20px;
    border: 1px solid #3fa7d6;
    min-width: 100%;
    border-radius: 10px;
    margin-left: none;
    background: #F9F9F9;
    display: flex;
    flex-direction: column;
}

.notes-content {
    margin-top: 10px;
    height: 100%;
}

.recent_notes_subtitle {
    color: #737373;
    opacity: 87%;
    font-size: 11px;
    font-style: italic;
  }

.customerpageDetailsEditbtn{
    margin-left: 50px;
    font-size: 15px;
}

#contact_call_icon {
    background-color: #007bff;
    width: 40px;
    height: 40px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

#myBtn {
    outline: none;
    border: none;
    background-color: #EFF5FF;
}