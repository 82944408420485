.topSpacing20 {
    margin-top: 20px;  
}
.icon-position {
    font-size: 30px;
    margin-top: 14px;
    margin-left: 12px
}
.error {
    color: red;
    font-size: 11px;
    padding-top: 2px;
}