.taskHeading{
    display: flex;
    justify-content: space-between;
}

.taskName{
    font-weight: 500;
}

.taskItem{
    margin-top: 20px;
}

.taskTime{
    color: rgba(0, 0, 0, 0.6);
}