.customerCardBody{
    border: 1.5px solid #E9E9E9;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding-right: 2%;
    background: #FFFFFF;
    cursor: pointer;
    margin-bottom: 5px;
}

.customerCardBody.active {
    background: #EFF5FF;
}

.customerCardBody.inactive {
    background: #FFFFFF;
}

.cardFooter{
    border-top: solid 1px #95D0EB;
    background-color: #EBF8FF;
}

.customercardpadding{
    padding-top: 25px;
}