.forms_info {
    padding: 15px 20px;
    border: 1px solid #3fa7d6;
    height: calc(100vh - 160px);
    width: 100%;
    min-height: 100%;
    border-radius: 10px;
    margin-left: none;
    background: #F9F9F9;
    overflow: hidden;
}

.customer_goal_icon {
    background-color: #007bff;
    width: 35px;
    height: 35px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}