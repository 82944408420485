.navbar {
  background: #ffffff;
  box-shadow: 5px 5px 5px grey;
  height: 70px;
  width: 100%;
  top: 0;
}

.selected > div {
  background: #3fa7d6;
  color: #ffffff;
}

/* .nav-item.dropdown {
    /* overflow: hidden!important; 
    overflow-x: hidden!important; /* Hide horizontal scrollbar 
}

.login-navbar {
    overflow: hidden!important;
}
#navbar-dropdown {
    overflow: hidden!important;
    border: 1px solid red!important;
    overflow-y: hidden!important; /* Hide vertical scrollbar 
    overflow-x: hidden!important; /* Hide horizontal scrollbar 
}
.login-button {
    overflow: hidden!important;
    border: 1px solid red!important;
} */
.list-group-item {
  border: 0px;
}

.list-group-item.active {
  background: #ebf8ff;
  border-radius: 4px;
  color: black;
  z-index: 0;
}

.list-group-item.active > span {
  color: #3fa7d6;
  z-index: 0;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #ebf8ff;
  color: black;
  border-bottom: 2px solid #3fa7d6;
  z-index: 0;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: black;
  z-index: 0;
}

.nav.nav-tabs {
  box-shadow: 0px 0px 0px grey;
  padding-left: 30px;
}

input[type="radio"].timeStrtIndicator:checked + label {
  background-color: #3fa7d6;
  color: #ffffff;
}

input[type="radio"].timeEndIndicator:checked + label {
  background-color: #3fa7d6;
  color: #ffffff;
}

/*
I disabled this because its applying to the entire site globally - Rami

button.btn-primary{
    height: 64px;
    left: 512px;
    top: 1056px;
    background: #3FA7D6;
    border-radius: 4px;
    border: 0px;
}
*/

button.btn-primary:hover {
  border: 1px solid #ebf8ff;
  color: white;
  background: #3fa7d6;
}

button.btn-outline-info-new {
  border: 1px solid #3fa7d6;
  color: #3fa7d6;
  background: white;
}

button.btn-outline-info-new:hover {
  border: 1px solid #3fa7d6;
  background: #3fa7d6;
  color: white;
}
