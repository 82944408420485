.recent_calls {
  padding: 15px 20px;
  border: 3px solid #3fa7d6;
  width: 100%;
  border-radius: 10px;
  margin-left: 10px;
}

.recent_calls_title {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.recent_calls_title_txt {
  font-size: 20px;
}

.contact_call_icon {
  background-color: #3fa7d6;
  width: 30px;
  height: 30px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.contact_tile {
  display: flex;
  align-items: center;
  margin-top: 1%;
  margin-bottom: 1%;
  padding: 1.5%;
  background: white;
  border-radius: 5px;
  border-bottom: 1px solid #e5e5e5;
}

.contact_tile_container :hover {
  background: white;
}

.contact_tile_container {
  background: white;
  cursor: pointer;
}

.contact_tile_name {
  font-size: 16px;
}

.contact_tile_subtitle {
  font-size: 12px;
  opacity: 60%;
  display: flex;
  align-items: center;
}

.contact_title_subtitle_title {
  padding-right: 1%;
}

.contact_title_subtitle_phone {
  padding-left: 1%;
  padding-right: 1%;
}

.recent_calls_subtitle {
  color: #737373;
  opacity: 87%;
  font-size: 11px;
  font-style: italic;
}

.contact_title_subtitle_duration {
  white-space: nowrap;
  padding-left: 1%;
  display: flex;
  align-items: center;
}
