.customerDetails span{
    color: rgba(0, 0, 0, 0.6);
}

.customerDetails h4{
    margin-bottom: 0;
}

.navigationHeader{
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding-right: 50px;
    margin-bottom: 30px;
}

.navigationHeader span{
    color: rgba(0, 0, 0, 0.6);
}

.customerEntitlement{
    margin-top: 20px;
    margin-bottom: 20px;
}

.itemBlock{
    margin-top: 20px;
    margin-bottom: 20px;
}

.phoneNumberBlock{
    display: flex;
}

.itemNumber{
    padding: 10px;
    background-color: #EBF8FF;
}

.phoneNumber{
    padding-top: 10px;
    padding-left: 10px;
}