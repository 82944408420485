#subtasks-add-modal{
    width: 20%;
    max-width: 100%;
}

#subtasks-add-modal .save-changes-button{
    width: 125px;
    height: 40px;
}

#subtasks-add-modal #show-modal-button{
    height: 60px;
    width: 220px;
    margin-bottom: 5px;
}

#subtasks-add-modal-body{
    width: 100%;
}

#subtasks-add-modal-row{
    width: 100%;
}

#subtasks-add-modal-row > *{
    margin-top: 4%;
}