.TasksPage span {
    color: rgba(0, 0, 0, 0.6);
}

.TasksPage {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 160px);
}

.TopPanel {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.newButton {
    margin-right: 10px;
    background-color: #3FA7D6;
    color: white;
    border: 1px solid white;
    border-radius: 4px;
}

.newButton:hover {
    border: 1px solid #3FA7D6;
}

.newButton:focus {
    outline: none;
}

.taskCardList {
    margin-top: 0.5%;
    overflow-y: auto;
    display: flex;
}

.undo {
    font-weight: 500;
    font-size: medium;
    text-decoration: underline;
    cursor: pointer;
}

.mr-sm-2.task_search_form {
    width: 400px !important;
    margin-bottom: 0.5vh;
}

.task_button_group {
    display: flex;
    align-items: center;
}

.task_button_group .project_button {
    border: 1px solid #99BDF3;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 0.5% 2%;
    white-space: nowrap;
    margin-left: 1%;
    color: #737373;
    opacity: 87%;
    font-size: 15px;
    margin-top: 2%;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.task_button_group .project_button.active {
    background: #DBE9FF;
    color: #000000;
    opacity: 87%;
}