.documents-container {
    width: 95%;
    height: min-content;
}
.documents-title-wrapper {
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
    margin-left: 2vw;
}
.documents-title-wrapper h1 {
    font-size: 1.75rem;
    overflow-y: hidden;
    font-family: Avenir, Arial, Helvetica, sans-serif;
    color: #333;
}
.upload-document-button {
    display: flex;
    border-radius: 25px;
    padding-left: 1rem;
    padding-right: 1rem;
    background: #FFD166;
    padding-bottom: 0;
    flex-direction: row;
    margin-left: auto;
    margin-right: 5%;
    margin-bottom: 1vh;
    height: min-content;
    box-shadow: 0px 1px 5px rgba(34, 34, 34, 0.3);
    transition: .2s ease-in-out;
}
.upload-document-icon {
    margin-top: 1.5vh;
    color: #333;
    font-size: 1.75rem!important;
}
.first-button {
    margin-left: 0!important;
}
.document-tabs-wrapper {
    margin-bottom: 1vh;
    padding-bottom: 30px;
    overflow-y: hidden;
}
.document-tabs-wrapper button {
    border: none;
    font-family: Avenir, Arial, Helvetica, sans-serif;
    color: #333;
    padding-left: .75rem;
    padding-right: .75rem;
    padding-top: .25rem;
    padding-bottom: .25rem;
    border-radius: 15px;
    outline: none;
    margin-left: 1vw;
    border: 2px solid #FFD166;
}
.document-tabs-wrapper button:active {
    background: #FFD166;
    border: 2px solid #FFD166;
}
.upload-document-button p {
    margin-top: 1.5vh;
    font-family: Avenir, Arial, Helvetica, sans-serif;
    color: #333;
    font-weight: 500;
    font-size: 1rem;
    margin-left: .5vw;
}
.upload-document-button:hover {
    box-shadow: 0px 2px 6px rgba(34, 34, 34, 0.4);
    transition: .2s ease-in-out;
    cursor: pointer;
}

.documents-content-wrapper {
    display: flex;
    flex-direction: row;
    /* border: 1px solid red; */
    height: 80%;
}
.documents-content-wrapper-left {
    display: flex;
    flex-direction: column;
    flex: 2;
    margin: .5rem;
    margin-left: 2vw;
    /* border: 1px solid red; */
}
.documents-content-wrapper-right {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: .5rem;
    /* border: 1px solid red; */
}
.documents-content-wrapper-right {
    /* border: 1px solid red; */
    font-family: Avenir, Arial, Helvetica, sans-serif;
    color: #333;
}
.case-notes {
    width: 95%;
    box-shadow: 0px 2px 6px rgba(34, 34, 34, 0.4);
    margin-left: 0%;
    background: #f5f5f5;
    border-radius: 10px;
    padding-bottom: 40px;
}
.case-notes-wrapper {
    width: 90%;
    box-shadow: 0px 2px 6px rgba(34, 34, 34, 0.4);
    background: #f0f0f0;
    margin-left: 5%;
    border: 1.5px solid #E9E9E9;
    border-radius: 5px;
}
.case-notes h3 {
    overflow-y: hidden;
    font-size: 1.75rem;
    margin-left: 1vw;
    margin-top: 1vh;
}
.case-notes p {
    font-family: Avenir, Arial, Helvetica, sans-serif;
    color: #333;
    margin-left: 1vw;
    margin-top: 1vh;
    width: 90%;
    font-size: 1rem;
    opacity: .8;
    font-weight: 400;
}
.case-notes-uploaded-by {
    font-weight: 900!important;
}

.comments-wrapper {
    width: 95%;
    box-shadow: 0px 2px 6px rgba(34, 34, 34, 0.4);
    margin-left: 0%;
    background: #f5f5f5;
    border-radius: 10px;
    /* padding-bottom: 40px; */
    margin-top: 2vh;
}
.comments-wrapper h3 {
    font-family: Avenir, Arial, Helvetica, sans-serif;
    color: #333;
    margin-left: 1vw;
    font-size: 1rem;
    opacity: .8;
    margin-top: 1vh;
    font-weight: 400;
}
.input-wrapper {
    width: 90%;
    margin-left: 5%;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    height: 50px;
    display: flex;
    margin-top: 5vh!important;
    margin-bottom: 1vh;
}

.input-wrapper-image-wrapper {
    display: flex;
    flex: 1;
}
.input-wrapper img {
    width: auto;
    height: 40px;
    border-radius: 360px;
}

.input-wrapper input {
    display: flex;
    border: none;
    /* margin-top: .75vh; */
}

.message-icon {
    margin-top: 1vh;
    margin-right: .25vw;
    margin-left: .5vw;
    font-size: 1.25rem!important;
    color: #333;
    opacity: .85;
}
.send-icon {
    margin-top: .6vh;
    margin-left: auto;
    margin-right: .5vw;
    font-size: 1rem!important;
    color: #333;
    opacity: .85;
}
.input-container {
    display: flex;
    flex: 5;
    height: 80%;
    border-radius: 10px;
    flex-direction: row;
    border: 0.75px solid #D6D6D6;
    background: #fff;
}

.file-component {
  margin-top: 2vw;
  margin-left: 2vw;
}

.MuiButton-label {
  color: #fff;
}