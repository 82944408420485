.support-coordinator-home-wrapper {
  padding: 0 1rem;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(190, 190, 190);
  border-radius: 22px;
}

::-webkit-scrollbar-track {
  background: rgba(250, 250, 250);
}

.customer-icon-button {
  outline: none;
}

.MuiDataGrid-pinnedColumns, .MuiDataGrid-pinnedColumnHeaders {
  background-color: #f9f9f9 !important;
}