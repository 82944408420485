#evvLogs-main .DatePickerParent{
    display:none;
}

#evvLogs-main .DayButton{
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #3fa7d6;
    color: white
}

#evvLogs-main .DayButton{
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #3fa7d6;
    color: white;
    outline: none;
}

#evvLogs-body{
    border-top: 1px solid grey;
    padding-top: 10px;
}