.TaskCard {
  border: 2px solid #95d0eb;
  padding: 25px;
  margin-right: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.TaskHeader {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0px;
}

.TaskHeaderTwo {
  padding-top: 0px;
  display: flex;
  justify-content: space-between;
}

.loaderContainer {
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noTaskFound { 
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}