input[type="file"] {
    display: none;
}

.upload-area {
  border: dashed 2px lightgray;
  border-radius: 20px;
  height: 125px;
  max-width: 320px;
  width: 100%;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 20px;
}