/* * {
    overflow-x: hidden;
}*/

.employee-home-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.employee-home-wrapper-top-row {
  width: 100%
}

.upcoming-appointments-container {
  width: 100%;
  height: calc(100% - 240px);
}
.upcoming-appointments-top {
  height: auto;
}
.upcoming-appointments-bottom {
  margin-top: 10px;
  display: flex;
  margin-bottom: 10px;
}

.upcoming-appointments-recentcalls {
  height: 100%;
  width: 50%;
}
.upcoming-appointments-timesheets {
  height: 100%;
  width: 50%;
}
/* .upcoming-appointments-wrapper {
  border: 3px solid #3fa7d6;
  border-radius: 10px;
  display: flex;
  flex: 1;
  margin: 1rem !important;
} */
/* .calendar-wrapper {
  border: 3px solid #3fa7d6;
  display: flex;
  background: #fff;
  border-radius: 10px;
  flex: 1;
  margin: 1rem !important;
}
.employee-home-wrapper-bottom-row {
  display: flex;
  flex-direction: row;
  max-height: 100%;
  /* width: 1300px; */
/* margin-top: 50px; */
/* margin-left: 15px;
.notifications-recent-calls-wrapper {
  border: 3px solid #3fa7d6;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  flex: 1.5;
  background: #fff;
  margin: 1rem !important;
}
.documents-wrapper {
  border: 3px solid #3fa7d6;
  display: flex;
  padding-bottom: 30px;
  /* height: 620px; */
/* flex-direction: row;
  border-radius: 10px;
  flex: 2.25;
  background: #fff;
  /* margin: 1rem!important; */
/* margin: 1rem;
  margin-left: -0.2rem; */
/* width:1210px; */
.notifications-wrapper {
  display: flex;
  flex: 1.5;
  margin: 0.5rem !important;
}
.recent-calls-wrapper {
  display: flex;
  flex: 1;
  margin: 1rem !important;
  /* margin-left: -1.5rem; */
}
.assignments-wrapper {
  border: 3px solid#3FA7D6;
  display: flex;
  border-radius: 10px;
  background: #fff;
  flex-direction: row;
  flex: 1;
  /* margin: 1rem!important; */
  height: 100%;
}
