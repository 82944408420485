.CustomerHeader{
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding-right: 20px;
}

.HorizontalLine{
    border-top: 1px solid #95D0EB;
}

.SecondaryFontColor{
    color: rgba(0, 0, 0, 0.6);
}