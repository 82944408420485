.OuterDiv{
    border: 2px solid #95D0EB;
    padding: 10px;
    margin: 10px 10px 0 0;
}

.title{
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin-left: 30px;
    margin-right: 20px;
    color: rgba(0, 0, 0, 0.6);
}

.infoOuterDiv{
    display: flex;
    align-items: center;
    margin-left: 30px;
}

.infoLogo{
    align-items: center;
}

.infoInnerDiv{
    margin-left: 30px;
}

.infoOne{
    color: rgba(0, 0, 0, 0.87);
    font-size: 1.3rem;
    padding: 0;
}

.infoTwo{
    color: rgba(0, 0, 0, 0.6);
}

.taskList{
    margin-top: 20px;
    padding: 0px 20px 0px 30px;
    overflow-y: auto;
    height: 300px;
}