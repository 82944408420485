.details_header {
    display: flex;
    align-items: center;
    color: #898888;
    font-size: 16px;
}

.details_header div {
    box-sizing: border-box;
    border-radius: 6px 6px 0px 0px;
    padding: 1%;
    margin-left: 2.5%;
    border-bottom: none;
    cursor: pointer;
}

.details_body {
    background: #ffffff;
    height: calc(100vh - 130px);
    min-height: 835px;
    overflow: hidden;
    padding: 15px;
}

.selected {
    background: #EFF5FF;
    color:#000000;
}
