.timesheets-arrow-div {
	font-size: 1rem;
	cursor: pointer;
}

.arrow-img {
	display: inline-block;
	position: relative;
	right: 7px;
	top: 1px;
}

.timesheets-arrow-span {
	font-size: 1rem;
	display: inline-block;
	position: absolute;
	top: 82px;
}
