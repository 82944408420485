.Artifacts{
    display:flex;
    margin-top: 10px;
    justify-content: space-between;
}

.Artifacts > ul{
    list-style: none;
    padding-left: 0;
    display: flex;
}

.Artifacts > ul > li{
    cursor: pointer;
    padding-left: 5px;
    margin-right: 15px;
}

.mr-sm-2.artifact {
    width: 400px !important;
    margin-bottom: 0.5vh;
}