.ConTopPanel {
    /* Remove fixed width and use full width */
    margin-bottom: 0 !important;
    width: 100% !important;
}

.ConPage {
    display: flex;
    flex-direction: column;
}

.ConPage .form-group.col {
    padding-left: 0% !important;
}

.contact-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}