.viewer {
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
  margin-top: 10px;
}

.mtTypeIcon {
  background-color: #1976D2;
  width: 30px;
  height: 30px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-left: 5px;
}

.mtDate {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mtClearIcon {
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

#report-main .DatePickerParent{
  display:none;
}

#report-main .DayButton{
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #007bff;
  color: white;
  outline: none;
}

/* .Mui-selected{
    background-color: #edebeb7a !important;
    font-weight: bold !important;
  }
  button.MuiButtonBase-root.MuiTab-root
  {
    text-transform: capitalize !important;
  } */