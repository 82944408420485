.customersPage-mainDiv .details_header {
    display: flex;
    align-items: center;
    color: #898888;
    background: #F9F9F9;
    font-size: 16px;
}

.customersPage-mainDiv .details_header div {
    box-sizing: border-box;
    width: 100px;
    text-align: center;
    border-radius: 6px 6px 0px 0px;
    padding: 1%;
    border-bottom: 1px solid #85C4E5;
    cursor: pointer;
}

.customersPage-mainDiv .details_body {
    background: #ffffff;
    min-height: calc(100vh - 500px);
    overflow: auto;
    scrollbar-gutter: stable;
    padding: 15px;
}

.customersPage-mainDiv .selected {
    background: #EFF5FF;
    color:#000000;
    border-bottom: 1px solid #85C4E5;
    border-top: 1px solid #EFF5FF;
}

.customersPage-mainDiv .unselected {
    background: #F9F9F9;
    color:#000000;
    border-bottom: none !important;
}