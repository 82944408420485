.note_tile {
  display: block;
  align-items: center;
  margin-top: 1%;
  margin-bottom: 1%;
  padding: 1.5%;
  background: white;
  border-radius: 5px;
  border-bottom: 1px solid #e5e5e5;
}

.note_tile_main {
  overflow: hidden
}

.note_tile_name {
  font-size: 16px;
  float: left;
}

.navlink_name_color {
  color: #3FA7D6;
}

.note_tile_name:hover {
  text-decoration: underline;
}

.note_tile_created {
  opacity: 60%;
  font-size: 12px;
  float: right;
}

.note_tile_desc {
  color: #000000;
  margin-top: 10px;
  font-size: 15px;
  opacity: 100%;
  display: block;
  align-items: baseline;
}

.notes-wrapper {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  border: 3px solid #3fa7d6;
  border-radius: 10px;
  margin-left: 10px;
}

.note-wrapper-top-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-shadow: 0 0 5px 0px black;
}

.note-subtitle-wrapper {
  margin-left: auto;
  margin-right: 3px;
}

.note-subtitle-wrapper p {
  font-family: Avenir, Arial, Helvetica, sans-serif;
  color: #333;
  font-size: 0.9rem;
  opacity: 0.8;
  margin-top: 2px;
  margin-right: 10px;
}

.recent_notes {
  padding: 15px 20px;
  border: 3px solid #3fa7d6;
  width: 100%;
  min-height: 100%;
  border-radius: 10px;
  margin-left: 10px;
}

.recent_notes_title {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.recent_notes_title_txt {
  font-size: 20px;
}

.recent_notes_subtitle {
  color: #737373;
  opacity: 87%;
  font-size: 11px;
  font-style: italic;
}

.note_tile_container {
  background: white;
  cursor: auto;
}

/* .sticky_note_icon {
  background-color: #3fa7d6;
  width: 30px;
  height: 30px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
} */