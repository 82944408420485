.customerform-personaldetails-datepicker{
    width: 40%;
    margin-left: 0.5rem !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.customerform-personaldetails-datepicker .MuiOutlinedInput-notchedOutline{
    border: 1px solid #add8e6;
}

.customerform-personaldetails-datepicker .MuiOutlinedInput-notchedOutline:focus{
    border: 1px solid #add8e6 !important;
}