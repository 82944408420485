.modal-style {
    max-width: 380px;
}

input[type='checkbox'] + label {
    padding-left: .3rem;
}

label {
    padding-left: .3rem;
}

.on-radio {
    margin-right: 2.5rem;
}

.after-radio {
    margin-right: 1.4rem;
}