.subtasks-checkout-modal{
    width: 60%;
    max-width: 100%;
}

.subtasks-checkout-modal-mini{
    width: 35%;
    max-width: 100%;
}

.subtasks-checkout-modal .save-changes-button{
    width: 125px;
    height: 40px;
}

.subtasks-checkout-modal-mini .save-changes-button{
    width: 125px;
    height: 40px;
}

#subtasks-checkout-modal-body{
    width: 100%;
    overflow-y: auto;
    max-height: 400px;
}

#subtasks-checkout-modal-row{
    width: 100%;
    display: inline-flex;
    border: 1px solid rgb(209, 209, 209);
    padding-bottom: 10px;
    padding-top: 10px;
}

#subtasks-checkout-modal-row > *{
    margin-left: 0.5%;
    margin-right: 0.5%;
}

.subtask-autocomplete *{
    font-size: 12;
}