.employeeform-otherdetails-datepicker{
    width: 76%;
    margin-top: 3% !important;
    margin-bottom: 3% !important;
}

.employeeform-otherdetails-datepicker .MuiOutlinedInput-notchedOutline{
    border: 1px solid #add8e6;
}

.employeeform-otherdetails-datepicker.MuiOutlinedInput-notchedOutline:focus{
    border: 1px solid #add8e6 !important;
}