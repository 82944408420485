.timesheet-wrapper-header {
  font-size: 1.75rem;
  font-family: Avenir, Arial, Helvetica, sans-serif;
  color: #333;
  margin: 2%;
}
.timesheet-wrapper-cm {
  width: 60%;
  height: 100%;
  overflow: hidden;
  border: 3px solid #3fa7d6;
  border-radius: 10px;
}
.timesheet-wrapper-top-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* margin-top: 2vh;
    margin-left: 2vw; */
  box-shadow: 0 0 5px 0px black;
}
.date-and-icon-wrapper {
  /* display: flex;
    flex-direction: row; */
  margin-left: auto;
  margin-right: 3px;
}
.date-and-icon-wrapper p {
  font-family: Avenir, Arial, Helvetica, sans-serif;
  color: #333;
  font-size: 0.9rem;
  opacity: 0.8;
  margin-top: 2px;
}
.timesheet-icon {
  color: #333;
  opacity: 0.8;
  /* margin-top: 10px;
    margin-left: 10px; */
  margin: 10px;
  margin-right: -2px;
}
.timesheet-cycle {
  display: flex;
  flex-direction: row;
  /* margin-left: 25%; */
  margin-top: 2vh;
  width: 100%;
  height: 40px;

  align-self: center;
}
.left-icon {
  font-size: 2rem !important;
  cursor: pointer;
}
.right-icon {
  margin-left: auto;
  cursor: pointer;
  font-size: 2rem !important;
}
.timesheet-cycle p {
  /* overflow: hidden; */
  margin-left: 2%;
  font-family: Avenir, Arial, Helvetica, sans-serif;
  color: #333;
  margin-top: 0.35vh;
  margin-right: 2%;
  text-align: center;
  width: 100%;
}
.timesheets-wrapper {
  margin-left: 1vw;
  margin-top: 1vw;
  width: 100%;
}
.timesheet {
  display: flex;
  border-radius: 5px;
  height: 110px;
  margin: 1rem;
  border: 2px solid #dfdfdf;
  flex-direction: row;
  justify-content: flex-start;
}
.check-mark {
  color: #4c956c;
  font-size: 2rem !important;
  margin-right: 1vw;
  margin-left: 0.5vw;
  margin-top: 0.75vh;
}
.timesheet-content {
  display: flex;
  margin: 0vh !important;
  flex-direction: column;
  font-family: Avenir, Arial, Helvetica, sans-serif;
  color: #333;
  /* border: 1px solid black; */
  width: 75%;
}
.timesheet-date {
  margin-top: 1vh;
}
.timesheet-time {
  margin-top: -1vh !important;
}
.timesheet-hours {
  margin-left: auto;
  margin-right: 1vw;
  margin-top: 1vh;
  font-family: Avenir, Arial, Helvetica, sans-serif;
  color: #333;
  font-size: small;
  margin-bottom: 0%;
}
