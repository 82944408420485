.messagePage-main{
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: 1px;
    font-family: Lato;
    font-size: 14px;
}

.messagePage-main.light{
    background-color: rgba(230, 247, 255, 1);
}

.messagePage-main .messagePage-content img{
    max-width: 25%;
}

.messagePage-main .messagePage-content i{
    font-size: 100px;
    color: rgb(63, 167, 214);
}

.messagePage-main .messagePage-content h5{
    margin-top: 5px;
    /* margin-bottom: 10px; */
}

.messagePage-main .messagePage-content p{
    color: #6c757d;
}



