@import url("https://fonts.googleapis.com/css?family=Roboto:400|Lato:400,700,500,600");

.timesheet-summary-main {
  font-family: "Lato";
  width: 100%;
  height: 100%;
  display: flex;
}

.timesheet-summary-child {
  font-size: 16px;
  font-family: "Lato";
  padding: 5px;
}

/* Summary Table Component */

#timesheets-summarytable-main {
  border-color: rgba(63, 167, 214, 1);
  border: 1px solid rgba(63, 167, 214, 1);
  border-radius: 4px;
  background-color: #f5f4f4;
}

#timesheets-summarytable-main .editButton {
  height: 75%;
}

#timesheets-summarytable-weeknavigation #previousweekbutton {
  margin-left: 100px;
  margin-top: 10px;
  width: 125px;
  float: left;
  background-color: rgba(63, 167, 214, 1);
  color: white;
  border: none;
  border-radius: 4px;
  height: 30px;
}

#timesheets-summarytable-weeknavigation #nextweekbutton {
  margin-right: 100px;
  margin-top: 10px;
  float: right;
  width: 125px;
  background-color: rgba(63, 167, 214, 1);
  color: white;
  border: none;
  border-radius: 4px;
  height: 30px;
}

.timesheets-summarytable-columnheader {
  font-size: 12px;
  overflow-wrap: normal;
  line-height: normal;
  white-space: normal;
}

#timesheets-summarytable-main .MuiDataGrid-colCellTitle {
  line-height: 20px;
  white-space: normal;
  text-align: center;
}

#timesheets-summarytable-main .MuiDataGrid-row {
  font-size: 13px;
}

#timesheets-summarytable-main .Mui-even {
  background-color: #f5f4f4;
}

#timesheets-summarytable-main .Mui-odd {
  background-color: #f7f7f7;
}

#timesheets-summarytable-actions {
  padding: 5px;
  display: flow-root;
}

#timesheets-summarytable-actions #actions-dropdown {
  background-color: rgba(63, 167, 214, 1);
  color: white;
  border: none;
  font-size: 15px;
  width: 15%;
  max-width: 180px;
  height: 40px;
  border-radius: 4px;
  float: right;
}

/* Employee Selection Sidebar*/

.new-timesheet-button {
  background-color: rgba(63, 167, 214, 1);
  color: white;
  border: none;
  font-size: 15px;
  width: 100%;
  height: 30px;
  border-radius: 4px;
}

#employee-select-dropdown {
  background-color: white;
  color: black;
  border: none;
  font-size: 15px;
  width: 100%;
  height: 60px !important;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 18px;
  border: 2px solid rgba(63, 167, 214, 1);
  font-weight: 600;
}

.dropdown-toggle {
  font-size: 15px !important;
}

.employee-select-dropdown {
  width: 100%;
  border-radius: 4px;
}

.employee-sidebar {
  max-width: 225px;
  width: 16%;
}

.employee-sidebar .dropdown-menu {
  width: 100%;
}

/*Employee Timesheets Approval Dropdown*/

#current-employee-text {
  float: left;
  font-weight: 600;
  margin-top: 2px;
  margin-left: 8px;
}

.employee-weekly-timesheets {
  width: 100%;
}

.weeklytimesheet {
  width: 100%;
}

.weeklytimesheetbutton {
  background-color: #ebf8ff;
  font-weight: 600;
  width: 100%;
  padding: 10px;
  text-align: left;
  margin-top: 15px;
  border: 2px solid rgba(63, 167, 214, 1);
  border-radius: 4px;
}

.week-approval-dropdown-collapse {
  display: none;
}

.week-approval-dropdown-collapse.in {
  display: block;
  background-color: rgb(240, 240, 240);
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-top: 20px;
}

.week-approval-dropdown-table {
  margin: auto;
  border: 2px solid rgba(63, 167, 214, 1);
}
/*
.tableheader{
    text-align:left !important;
    padding-left:10px;
    font-weight:600;
}
*/
.week-approval-dropdown-tablecell {
  width: 145px;
  height: 50px;
  text-align: center;
  border: 2px solid rgba(63, 167, 214, 1);
}

.week-approval-dropdown > div > .MuiDataGrid-main {
  background-color: white;
  font-size: 13px;
  min-height: 350px;
  height: 100%;
  border-top: 1px solid rgba(63, 167, 214, 1);
  border-bottom: 1px solid rgba(63, 167, 214, 1);
  border-left: 1px solid rgba(63, 167, 214, 1);
}

.week-approval-dropdown
  > div
  > .MuiDataGrid-main
  > .MuiDataGrid-columnsContainer {
  border-right: 1px solid rgba(63, 167, 214, 1);
}

.week-approval-dropdown
  > div
  > .MuiDataGrid-main
  > .MuiDataGrid-columnsContainer
  > div
  > .MuiDataGrid-colCell {
  border-top: 1px solid rgba(63, 167, 214, 1);
  border-right: 1px solid rgba(63, 167, 214, 1);
  padding-right: 0px;
  padding-left: 5px !important;
}

.weeklytimesheet
  > div
  > div.MuiDataGrid-root.MuiDataGrid-root
  > div.MuiDataGrid-main
  > div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  > div.MuiDataGrid-row.Mui-even
  > div {
  border-top: 1px solid rgba(63, 167, 214, 1);
  border-right: 1px solid rgba(63, 167, 214, 1);
  padding-right: 0px;
  padding-left: 5px !important;
}

.weeklytimesheet
  > div
  > div.MuiDataGrid-root.MuiDataGrid-root
  > div.MuiDataGrid-main
  > div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  > div.MuiDataGrid-row.Mui-odd
  > div {
  border-top: 1px solid rgba(63, 167, 214, 1);
  border-right: 1px solid rgba(63, 167, 214, 1);
  padding-right: 0px;
  padding-left: 5px !important;
}

#summary-edit-dropdown {
  height: 20px !important;
}

#summary-edit-dropdown::after {
  display: block;
}

#timesheets-summarytable-weeknavigation {
  background-color: rgba(230, 230, 230, 0.9);
  min-height: 50px;
  width: 100%;
}

#current-week-display {
  margin-top: 5px;
  margin-left: 20%;
  float: left;
  font-weight: 600;
  margin-top: 15px;
  color: rgba(63, 167, 214, 1);
  font-size: 20px;
}

.summary-edit-dropdown-option {
  font-size: 13px;
}

#summary-table-div > div > #gigatable {
  margin: 0px;
}

.weeklytimesheet .MuiDataGrid-iconButtonContainer {
  display: none;
}

#timesheets-summarytable-main .MuiDataGrid-iconButtonContainer {
  display: none;
}
