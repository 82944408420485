.mm-container {
    margin: 30px;
}

.mm-container .MuiButton-root {
    background-color: transparent;
    color: #3fa7d6;
    outline: 1px solid #3fa7d6;
    box-shadow: none;
    width: 100px;
}

.mm-container .MuiButton-root:hover {
    box-shadow: none;
}

.identifying-note {
    background-color: lightgray;
    border-radius: .5rem;
}

.identifying-information-container .row{
    height: auto;
    min-height: 70px;


}

.identifying-information-container {
    margin-bottom: 25px;
    padding-right: 25px;
    padding-left: 25px;
}

.mm-submit-container {
    margin: 1rem;
    display: flex;
    justify-content: center;
}

.mm-submit-button {
    width: 100px;
}

.mm-modal-width {
    width: 900px;
    max-width: none !important;
}

.mm-header {
    display: flex;
    justify-content: center;
}