.document-card {
    display: flex;
    height: 225px;
    margin-top: 2vh;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(34, 34, 34, 0.4);
    background: #f5f5f5;
    flex-direction: row;
}
.document-card-image-wrapper {
    margin-top: 3%;
}

.document-card-image-wrapper button {
    width: 90%;
    height: 15%;
    border-radius: 5px;
    margin-left: 5%;
    box-shadow: 0px 1px 6px rgba(34, 34, 34, 0.4);
    font-size:.85rem;
    font-weight: 500;
    font-family: Avenir, Arial, Helvetica, sans-serif;
    color: #333;
    outline: none;
    border: none;
    background: #A0EFDB;
    transition: .2s ease-in-out;
}
.document-card-image-wrapper button:hover {
    box-shadow: 0px 2px 6px rgba(34, 34, 34, 0.4);
    transition: .2s ease-in-out;
}
.document-card-image-content {
    display: flex;
    flex-direction: column;
    flex: 2;
}
.doc-title-wrapper {
    display: flex;
    margin-top: 2vh;
    flex-direction: row;
}
.new-icon {
    padding-left: .75rem;
    padding-right: .75rem;
    padding-top: .45rem;
    padding-bottom: .45rem;
    font-size:.85rem;
    box-shadow: 0px 1px 3px rgba(34, 34, 34, 0.3);
    font-weight: 500;
    margin-left: 1vw;
    background: #FFD166;
    border-radius: 10px;
    font-family: Avenir, Arial, Helvetica, sans-serif;
    color: #333;
}
.doc-title-wrapper h1 {
    margin-left: 1vw;
    font-family: Avenir, Arial, Helvetica, sans-serif;
    color: #333;
    overflow-y: hidden;
    margin-top: .5vh;
    font-size: 1.25rem;
}
.document-card-image-right {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.author-wrapper {
    display: flex;
    margin-top: .5vh;
    flex-direction: row;
}
.author-icon {
    margin-left: 1vw;
    color: #333;
    font-size: 1.8rem!important;
}
.author-wrapper h1 {
    font-family: Avenir, Arial, Helvetica, sans-serif;
    color: #333;
    overflow-y: hidden;
    margin-top: .75vh;
    margin-left: .5vw;
    font-size: .85rem;
    opacity: .8;
}

.doc-notes {
    margin-left: 1vw;
    width: 85%;
    font-weight: 300;
    font-size: 1.1rem;
    font-family: Avenir, Arial, Helvetica, sans-serif;
    color: #333;
    margin-top: 2vh;
}

.document-card-image-right button {
    width: min-content;
    white-space: nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: .5vh;
    padding-left: .75rem;
    padding-right: .75rem;
    height: 15%;
    border-radius: 5px;
    box-shadow: 0px 1px 6px rgba(34, 34, 34, 0.4);
    font-size:.85rem;
    font-weight: 500;
    font-family: Avenir, Arial, Helvetica, sans-serif;
    color: #333;
    outline: none;
    border: none;
    margin-top: auto;
    background: #A0EFDB;
    transition: .2s ease-in-out;
    transition: .2s ease-in-out;
}
.document-card-image-right button:hover {
    box-shadow: 0px 2px 6px rgba(34, 34, 34, 0.4);
    box-shadow: 0px 2px 6px rgba(34, 34, 34, 0.4);
    transition: .2s ease-in-out;
}
.document-cards-wrapper {
    /* margin-top: 1vh; */
    z-index: -1;
}