.App {
  background-color: #fafafa;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
}
.content {
  height: calc(100% - 120px);
  width: 100%;
  margin: 0;
  padding: 0;
  margin-top: 70px;
}

html,
body {
  /* max-height: 100%; */
  /* overflow-x:hidden; */
  /* overflow-y:hidden; */
}

body::-webkit-scrollbar-track {
  background: #fafafa;
}

body::-webkit-scrollbar-thumb {
  background-color: #bebebe;
  border-radius: 22px;
}

body::-webkit-scrollbar {
  display: none;
}

.btn-info,
.btn-info:active,
.btn-info:hover,
.btn-outline-info:active,
.btn-outline-info:hover {
  background-color: #3fa7d6;
  border-color: #3fa7d6;
  margin: 0;
}

@media only screen and (min-width: 1450px) {
  .App {
    overflow-x: hidden;
  }
  /* .App {overflow-x: hidden;} */
}
/* @media only screen and (min-width: 200px) {
  html {overflow-x: auto;}
  body {overflow-x: auto;}
} */

/*
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
*/

input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 5px 0px white inset;
}
input[type="radio"] + label {
  padding-left: 0.5rem;
}
input[type="radio"]:hover {
  box-shadow: 0 0 5px 0px white inset;
}
input[type="radio"]:before {
  content: "";
  display: block;
  width: 55%;
  height: 55%;
  margin: 23% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: black;
}

.form-label {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.6);
  padding-top: 15px;
}

.MuiTablePagination-displayedRows {
  position: relative;
  top: 8px;
}

.MuiTablePagination-selectLabel {
  position: relative;
  top: 6px;
}

.FW-Button{
    background-color: #3fa7d6;
    border-color: #3fa7d6;
    font-family: 'Lato';
    color: white;
    border-radius: 5px;
}

.FW-Button:hover{
  color: white
}

.FW-Button:focus {
    outline: none;
  }

.FW-Button:hover,
.FW-Button:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.FW-Button:hover {
  transform: translateY(-1px);
}

.FW-Button:active {
  background-color: #23617D;
  box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
  transform: translateY(0);
}