.projectNotes-mainDiv::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.projectNotes-mainDiv {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 210px);
}

.notes_info {
    padding: 15px 20px;
    border: 1px solid #3fa7d6;
    height: calc(100vh - 210px);
    border-radius: 10px;
    background: #F9F9F9;
    overflow: auto;
    margin-bottom: 15px;
}