.handycard-subtasks-modal {
  width: 75%;
  max-width: 100%;
}

.signature-display-modal {
  width: 40%; /* Adjust the width as needed */
  max-width: none; /* Allow the modal to exceed the default maximum width */
  height: 100%; /* Adjust the height as needed */
  margin: 1rem auto;
}

.TaskCard {
  border: 2px solid #95d0eb;
  padding: 25px;
  margin-right: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.EmpLocCard {
  border: 2px solid #95d0eb;
  display: flex;
  padding: 15px;
}

.ContactCard,
.EmpCard,
.ArtCard,
.CommentCard {
  border: 2px solid #95d0eb;
  padding: 15px;
}

.CommentCard {
  background-color: white;
}

.TaskHeader {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0px;
}

.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #3fa7d6;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

.hover-underline-sign {
  cursor: pointer;
}

.hover-underline-sign:hover {
  text-decoration: underline;
}

.signature-info table {
  width: 100%;
  border-collapse: collapse;
}

.signature-info td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.signature-info td:first-child {
  width: 150px;
  font-weight: bold;
}

.signature-info .signature-image {
  max-width: 100%;
  border: 1px solid #000;
  border-radius: 5px;
}



.TaskHeaderTwo {
  padding-top: 0px;
  display: flex;
  justify-content: space-between;
  
}

.TaskHeaderThree {
  padding-top: 0px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  
}

.overDue {
  background-color: #ffd166;
  padding-left: 10px;
  padding-right: 10px;
  color: black;
  font-weight: 500;
}
