.patient_top_appointments {
  padding: 1% 2%;
  border: 3px solid #3fa7d6;
  width: 100%;
  border-radius: 10px;
  height: 100%;
}

.patient_top_appointments_number {
  font-size: 11px;
  font-style: italic;
  color: #737373;
  margin-bottom: 0.5%;
}

.top_appointment_left_pane {
  margin-top: 1%;
  height: 340px;
  width: 257px;
}

.left_pane_day_title {
  font-size: 12px;
  padding: 1% 5%;
  border-radius: 20px;
  width: max-content;
  cursor: pointer;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 5%;
}

.left_pane_day_title.active {
  background: #dbe9ff;
  color: black;
}

.left_pane_day_title.inactive {
  background: white;
  border: 1px solid #99bdf3;
  color: #737373;
}

.left_pane_appointment {
  font-size: 10px;
  padding: 0.5% 1%;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 2%;
  margin-left: 5%;
  width: 90%;
  /* overflow-x: hidden; */
  height: 15px;
}

.left_pane_appointment.active .left_pane_appintment_bullet {
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background: #0b61e2;
  margin-right: 2%;
}

.left_pane_appointment.inactive .left_pane_appintment_bullet {
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background: #9fc4fe;
  margin-right: 2%;
}

.left_pane_appointment.active .left_pane_appintment_title {
  color: black;
  background: #dbe9ff;
  border-radius: 10px;
  padding: 1% 5%;
}

.left_pane_appointment.inactive .left_pane_appintment_title {
  color: #737373;
  background: #f5f5f5;
}

.appointment_card {
  width: 100%;
  height: 90%;
}

.appointment_card_nav_bullet.ACTIVE {
  background: #9d9d9d;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 2%;
  cursor: pointer;
}

.appointment_card_nav_bullet.INACTIVE {
  background: #d6d6d6;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 2%;
  cursor: pointer;
}

.card {
  width: 100%;
  padding: 1%;
  border: 2.5px solid #e9e9e9;
  border-radius: 5px;
  height: fit-content;
}

.card_title {
  font-size: 12px;
  color: #545454;
  margin-left: 2%;
  margin-right: 2%;
}

.card_title_card {
  width: 100%;
  padding: 2%;
  border-radius: 5px;
  background: #dbe9ff;
  font-size: 16px;
  color: black;
  margin: 1%;
}

.card_title_card_bullet {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 2%;
  background: #0b61e2;
}

.card_start_time {
  font-size: 14px;
  margin-left: 5%;
  display: flex;
}

.card_check_in_location {
  font-size: 10px;
  color: #707070;
  margin-left: 2%;
  white-space: nowrap;
  padding: 1%;
}

.card_check_in_button {
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 1% 3%;
  border: 1.5px solid #06d6a0;
  border-radius: 20px;
  cursor: pointer;
}

.card_check_in {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  background: #f5f5f5;
  margin: 7px;
  display: flex;
}

.card_map {
  height: 147px;
  margin-left: 1%;
  width: 100%;
  border-radius: 5px;
}

.notice_reminder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3%;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  border-radius: 5px;
  background: #f5f5f5;
  margin-top: 2%;
}

.notice_reminder_title {
  font-size: 11px;
}

.notice_reminder_subtitle {
  font-size: 9px;
}

.contact_card {
  width: 100%;
  height: 155px;
  padding: 3%;
  border-radius: 5px;
  background: #f5f5f5;
  margin-top: 2%;
  color: #707070;
}

.contact_card_session {
  width: 100%;
  height: 271px;
  padding: 3%;
  border-radius: 5px;
  background: #f5f5f5;
  margin-top: 2%;
  color: #707070;
}

.contact_card_title {
  font-size: 11px;
}

.contact {
  display: flex;
  margin-top: 5%;
  width: 100%;
}

.contact_name {
  font-size: 11px;
  color: black;
  white-space: nowrap;
}

.contact_name_subtitle {
  font-size: 9px;
  color: #707070;
}

.contact_call_icon {
  background-color: #a0efdb;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center {
  position: relative;
  left: 2px;
  top: 5px;
}

.contact_description {
  font-size: 10px;
  height: 40px;
  width: 230px;
  display: block;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contact_description-tooltip {
  font-size: 12px;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s ease;
  bottom: 150%;
  padding: 10px;
  border-radius: 2px;
  background: #f5f5f5;
  color: #737373;
  box-shadow: 0px 0px 12px rgba(2, 2, 111, 0.4);
}

.contact_description:hover + .contact_description-tooltip {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.card_request {
  width: 100%;
  padding: 7px;
  border-radius: 5px;
  background: #f5f5f5;
  margin-top: 2px;
  color: #707070;
}

.card_arrow {
  color: #3fa7d6;
}

.card_arrow :hover {
  color: #ababab;
}

.card_reschedule {
  padding: 2% 4%;
  display: flex;
  font-size: 9px;
  white-space: nowrap;
  color: white;
  background: #ababab;
  border-radius: 5px;
  cursor: pointer;
}

.card_cancel {
  padding: 2% 4%;
  display: flex;
  font-size: 9px;
  white-space: nowrap;
  color: white;
  background: #ff7575;
  border-radius: 5px;
  cursor: pointer;
}

.calendar {
  max-width: 100%;
  height: inherit;
  /* height: 50rem; */
}

.calendar_container {
  padding: 1.5%;
  background: #f5f5f5;
  border-radius: 5px;
  margin-left: auto;
}

.calendar_title {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
}

.calendar_toggle {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 10px;
  font-style: italic;
}

.calendar_component {
  border: none !important;
  background: #f5f5f5 !important;
  margin-top: 1%;
  font-size: 14px;
}

.calendar_component .react-calendar__navigation__label__labelText {
  font-size: 16px;
}

.highlight {
  color: #0b61e2;
  text-decoration: underline;
  text-decoration-color: #0b61e2;
}

.today {
  border: 2px solid #b8d4ff !important;
  border-radius: 30px;
  background: none !important;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar__tile {
  pointer-events: none !important;
}

.carousel.slide {
  width: 100%;
}

.react-calendar {
  width: 266px !important;
  height: 305px !important;
}
