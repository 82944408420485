.landingPageLoginDiv .buttonDiv{
    display: flex;
    margin-bottom: 5px;
}

.landingPageLoginDiv .buttonDiv .signInButton{
    width: 40%;
}

.landingPageLoginDiv .buttonDiv .signUpButton{
    display: flex;
    width: 20%;
    margin-left: 10px;
}

.landingPageLoginDiv .extraButtonDiv > p{
    margin-bottom: 5px !important;
}

.landingpage_recoverySnackbar{
   
}

.companyalert{
    background-color: #D32F2F;
    color: white;
    font-family: Lato

}

.companyinfo{
    background-color: #0a5cd7;
    color: white;
    font-family: Lato

}

.companyAgencyError{
    padding: 0px !important;
}