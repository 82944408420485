.identifying-label {
    font-weight: bold;
}

.identifying-note {
    background-color: lightgray;
    width: 100%;
    border-radius: .5rem;
    padding: .5rem;
    margin-top: 1rem;
    font-weight: 500;
    font-style: italic;
}

.identifying-information-container .row{
    height: 75px
}

.identifying-information-container {
    margin: 0;
    margin-bottom: 25px;
}

.mm-submit-container {
    margin: 1rem;
    display: flex;
    justify-content: center;
}

.mm-submit-button {
    width: 100px;
}