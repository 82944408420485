#timesheets-summarytable-actions {
  display: flex;
  justify-content: space-between;
  min-height: 50px;
}

#timesheets-summarytable-actions > div {
  right: 20px;
}

#timesheets-summarytable-actions > div > #actions-dropdown {
  width: 120px;
}
#timesheets-summarytable-actions > div > .dropdown-menu {
    margin-right: '20px' !important;
}
.active-cell {
  color: blue;
}
.submitted-cell {
  color: orange;
}
.approved-cell {
  color: green;
}
.open-cell {
  color: red;
}
.archived-cell {
  color: grey;
}
