.hideMe{
    display: none;
}

.customerTableDiv .MuiDataGrid-cell{
    overflow-x: overlay !important;
}

.customerTableDiv .MuiDataGrid-cellContent{
    overflow: visible !important;
}