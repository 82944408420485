#claims-main .DatePickerParent {
    display: none;
}

/* #claims-main .DayButton{
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #3fa7d6;
    color: white
} */

#claims-main .DayButton {
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    /* background-color: #3fa7d6; */
    color: white;
    outline: none;
}

.flex-item {
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    width: 100%;
}

.flex-item-u {
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    width: 30%;
}

.flex-item-d {
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    width: 45%;
}

.flex-item-d1 {
    margin-top: 15px;
    margin-left: 10px;
    width: 35%;
}

.flex-item-d2 {
    margin-top: 15px;
    margin-right: 10px;
    width: 10%;
}

.flex-container {
    display: flex-end;
    flex-direction: row;
    width: 100%;
    margin-top: -30px;
}

.flex-container>.right {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 100%;
}