
  
  .draggable {
    position: absolute;
    top: 0px;
    left: 0px;
    max-width: 200px;
    border: 1px solid #dedede;
  }
  
  .draggable-panel {
    padding: 10px;
    background-color: #eee;
    cursor: move;
  }
  
  .draggable-content {
    padding: 15px;
  }
  