.cardBody {
  border: 1.5px solid #e9e9e9;
  margin-bottom: 15px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding-right: 2%;
  background: #ffffff;
  cursor: pointer;
}

.cardBody.active {
  background: #eff5ff;
}

.cardBody.inactive {
  background: #ffffff;
}

.cardFooter {
  border-top: solid 1px #95d0eb;
  background-color: #ebf8ff;
}