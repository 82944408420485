.customerListHeader{
    justify-content: space-between;
    margin-top: 2%;
}

.customerListHeaderItems{
    display: inline-block;
    list-style: none;
    margin-left: -40px;
    margin-top: 20px;
}

.customerListUL{
    margin-bottom: 0;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
}

.mr-sm-2 {
    width: 42.5vw !important;
}

.col-md-8 {
    width: 100% !important;
    flex: 1;
    max-width: 100%;
}

.customer_button_group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.customer_button {
    border: 1px solid #99BDF3;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 1% 3%;
    white-space: nowrap;
    color: #737373;
    opacity: 87%;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.customer_button_number {
    width: 20px;
    height: 20px;
    background: #9FC4FE;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    margin-left: 0.5vw;
}

.customer_button.active {
    background: #DBE9FF;
    color: #000000;
    opacity: 87%;
}
