.date-time-picker-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}
.date-time-picker-label {
    display: flex;
    margin-left: 1rem;
    flex-direction: row;
}
.employee-wrapper {
    display: block !important;
    margin-top: 1vh;
    flex-direction: row;
    background: #E8F1FF;
    border-radius: 5px;
    padding: .5rem;
    margin-left: 1vw;
}
.left-side {
    display: flex;
    flex-direction: column;
    flex: 5;
}
.right-side {
    display: flex;
    flex: 1;
}
.right-side button {
    height: 2rem;
    width: 2rem;
    margin-left: auto;
    margin-right: .5vw;
    border: none;
    background: #fff;
}
.employee-wrapper-header {
    flex-direction: row;
}
.employee-wrapper-header h3 {
    font-size: 1rem!important;
    opacity: .8;
    font-weight: 400;
}
.employee-wrapper-body h3 {
    font-size: 1.1rem!important;
    font-weight: 500;
}
.employee-wrapper-body {
    display: flex;
    margin-top: .5vh;
    flex-direction: row; 
}
.employee-name {
}
.employee-type {
}

.recordactivityform-main-datetimepicker{
    width: 45%;
    margin-top: 2% !important;
    margin-bottom: 2% !important;
    margin-right: 10px !important;
}

.recordactivityform-main-datetimepicker .MuiOutlinedInput-notchedOutline{
    border: 1px solid #add8e6;
}