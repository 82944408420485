#claims-main .DatePickerParent{
    display:none;
}

/* #claims-main .DayButton{
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #3fa7d6;
    color: white
} */

#claims-main .DayButton{
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #3fa7d6;
    color: white;
    outline: none;
}

.flex-item {
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    width:100%;
}

.flex-item-u {
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    width:30%;
}

.flex-item-d {
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    width:45%;
}

.flex-item-d1 {
    margin-top: 15px;
    margin-left: 10px;
    width:35%;
}

.flex-item-d2 {
    margin-top: 15px;
    margin-right: 10px;
    width:10%;
}

.flex-container {
    display:flex-end;
    flex-direction:row;
    width:100%
}

.flex-container > .right{
    display:flex;
    justify-content: flex-end;
    flex-direction:row;
    width:100%;
}

.overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(16, 16, 16, 0.8);
}

.overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.overlay__content__text {
    /* left: 50%;
    position: absolute;
    top: 55%;
    transform: translate(-50%, -50%); */
    color: #3fa7d6;
}

.spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #3fa7d6;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
}

.crop { 
    overflow:hidden; 
    white-space:nowrap; 
    text-overflow:ellipsis; 
    width:100px; 
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}