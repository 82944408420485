.customer-date-box{
    width: 48%;
    margin-left: 4px !important;
    margin-right: 4px !important;
    margin-top: 3% !important;
    margin-bottom: 3% !important;
}

.customer-date-box .MuiOutlinedInput-notchedOutline{
    border: 1px solid #add8e6
}